import React from "react";
import PropTypes from "prop-types";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Icon, Popover } from "antd";
import { Link } from "gatsby";
import Image from "./image";

import "../styles/layout.css";
import "../styles/header.css";

const Layout = ({ children }) => {
    return (<div className="layout">
        <header className="fixed">
            <div className="left">
                <div>
                    <Image src="piggy-bank.png" />
                </div>
                <div>EJBank</div>
            </div>
            <div className="right">
                <ul>
                    <li>
                        <div className="menu">
                        <Popover placement="bottomRight" content={
                        <React.Fragment>
                            <ul style={{marginRight: "30px"}}>
                            <li><AnchorLink to="/#I">1 - Objectif</AnchorLink></li>
                            
                            <li><AnchorLink to="/#II">2 - Cahier des charges</AnchorLink></li>
                            <ul>
                                <li><AnchorLink to="/#II-I">I. Contexte</AnchorLink></li>
                                <li><AnchorLink to="/#II-II">II. Fonctionnalités</AnchorLink></li>
                                <li><AnchorLink to="/#II-III">III. Matrice des rôles</AnchorLink></li>
                                <li><AnchorLink to="/#II-IV">IV. Base de données</AnchorLink></li>
                            </ul>
                            
                            <li><AnchorLink to="/#III">3 - Travail à réaliser</AnchorLink></li>
                            <ul>
                                <li><AnchorLink to="/#III-I">I. Enterprise Java Bean</AnchorLink></li>
                                <li><AnchorLink to="/#III-II">II. Front API</AnchorLink></li>
                                <li><AnchorLink to="/#III-III">III. Starter</AnchorLink></li>
                            </ul>
                            
                            <li><AnchorLink to="/#IV">4 - Modalités du rendu</AnchorLink></li>
                            <ul>
                                <li><AnchorLink to="/#IV-I">I. Consigne</AnchorLink></li>
                                <li><AnchorLink to="/#IV-II">II. Notation</AnchorLink></li>
                                <li><AnchorLink to="/#IV-III">III. Planning</AnchorLink></li>
                            </ul>
                            
                            <li><AnchorLink to="/#V">5 - Documentation</AnchorLink></li>
                            <ul>
                                <li><AnchorLink to="/#V-I">I. Pré-requis</AnchorLink></li>
                                <li><AnchorLink to="/#V-II">II. Installations</AnchorLink></li>
                                <li><AnchorLink to="/#V-III">III. Récupération du projet Git</AnchorLink></li>
                                <li><AnchorLink to="/#V-IV">IV. Démarrer le projet</AnchorLink></li>
                                <li><AnchorLink to="/#V-V">V. Importer le projet dans l'IDE</AnchorLink></li>
                            </ul>
                            
                            <li><AnchorLink to="/#VI">6 - Annexes</AnchorLink></li>
                            <ul>
                                <li><AnchorLink to="/#VI-I">I. Schémas</AnchorLink></li>
                                <li><AnchorLink to="/#VI-II">II. Versions</AnchorLink></li>
                                <li><AnchorLink to="/#VI-III">III. Changelog</AnchorLink></li>
                            </ul>
                            </ul>
                        </React.Fragment>
                        }>
                        <Icon type="bars" />
                        </Popover>
                            
                        </div>
                    </li>
                    <li>
                        <div className="button">
                            <Link to="/accounts">Console</Link>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="clear"></div>
        </header>
        <div className="gradiant"></div>
        <div className="content">{children}</div>
    </div>);
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
